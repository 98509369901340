import React, { useState } from "react";
import {
  RemoteMediaList,
  Video,
  PeerControls,
  PeerList,
  LocalMediaList,
} from "@andyet/simplewebrtc";
import styled from "styled-components";

import useFullscreenStatus from "./useFullscreenStatus";
import Me from "./Me";

//Image
import Icon from "../../assets/options.svg";
import IconNoVideo from "../../assets/pacient.svg";
import IconNoVideoDoctor  from "../../assets/doctor.svg";
import IconMute from "../../assets/muted.svg";

const calcWidth = (size, type, maxi, address) => {
  if (type === "desktop") {
    if (size <= 4) {
      if (maxi) {
        if (maxi === address) {
          return "100%";
        }
        return "100%";
      }
      return "48%";
    }
    if (size >= 5) {
      if (maxi) {
        if (maxi === address) {
          return "100%";
        }
        return "70%";
      }
      return "30%";
    }
  }
  if (type === "mobile") {
    if (size <= 2) return "85%";
    if (size >= 3) return "48%";
    if (size >= 5) return "35%";
  }
};

const calcHeight = (size, type, maxi, address) => {
  if (type === "desktop") {
    if (size <= 2) {
      if (maxi) {
        if (maxi !== address) {
          return "50%";
        }
        return "100%";
      }
      return "100%";
    }
    if (size === 3) {
      if (maxi) {
        if (maxi !== address) {
          return "25%";
        }
        return "100%";
      }
      return "48%";
    }
    if (size >= 4) {
      if (maxi) {
        if (maxi !== address) {
          return "20%";
        }
        return "100%";
      }
      return "48%";
    }
  }
  if (type === "mobile") {
    if (size <= 2) return "50%";
    if (size <= 4) return "48%";
    if (size >= 5) return "32%";
  }
};

export const BoxVideo = styled.div`
  position: relative;
  display: flex;
  ${(props) => (props.maximized === props.address ? "flex : 1" : null)};
  align-items: center;
  justify-content: center;
  background: #0b4966;
  border-radius: 8px;
  overflow: hidden;
  width: ${(props) =>
    calcWidth(props.size, "desktop", props.maximized, props.address)};
  height: ${(props) =>
    calcHeight(props.size, "desktop", props.maximized, props.address)};

  @media (max-width: 1024px) {
    margin-bottom: .8rem;
    width: ${(props) => calcWidth(props.size, "mobile")};
    height: ${(props) => calcHeight(props.size, "mobile")};
  }

  @media (max-width: 648px) {
    margin-bottom: .3rem;
  }
`;

const BoxVideoGuestText = styled.p`
  color: #fff;
  font: 400 1.3rem 'Open Sans', serif;
`;

const Legend = styled.p`
  position: absolute;
  left: 0;
  bottom: -1px;
  margin: 0 4px;
  background-color: rgba(11, 73, 102, 0.7);
  font: 400 1rem Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  width: 100%;
  margin: 0;
  padding: 0.25rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Toolbar = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  // background: rgba(11, 73, 102, 0.7);
  border-radius: 5px;
  overflow: hidden;
  display: flex;

  @media (max-width: 667px) {
    display: none;
  }
`;

const IconOption = styled.img`
  padding: 0.9rem 0.3rem;
  width: 36px;
  background-color: rgba(11, 73, 102, 0.7);
  font: 400 1rem Open Sans;
  border-radius: 0.313rem;
  cursor: pointer;
`;

const ToolbarButton = styled.button`
  padding: 0.65rem 1rem;
  background: #ffffff;
  font: 400 1rem Open Sans;
  color: #000000;
  text-align: left;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
		background-color: #DCDCDC;
	}
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 667px) {
    position: static;
    // height: ${(props) =>
    props.videoOnly ? "100vh" : "calc(100vh - 150px)"};
    transform: translate(0, 0);
  }
`;

const PeerContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`;

const Maximized = styled.div`
  width: 70%;
  height: 100%;
`;

const Peers = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  flex-direction: ${(props) => (props.size === 3 || props.size === 5  ? "row" : "column")};
  align-items: center;
  width: ${(props) => (props.maximized ? "25%" : "100%")};
  height: ${(props) => (props.maximized ? "100%" : "100%")};

  @media(max-width: 1024px) {
    flex-flow: ${(props) => (props.size === 2 ? "column" : "row wrap")};
  }

  @media(max-width: 648px) {
    flex-flow: row wrap;
  }
`;

const ContainerNoVideo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoVideo = styled.img`
  width: 5rem;
`;

const ContainerLegend = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: rgba(11, 73, 102, 0.7);
  display: flex;
  align-items: center;
`;

const LegendPeer = styled.p`
  margin-left: ${(props) => (props.isMuted ? '.5rem' : '1rem')};
  font: 400 1rem Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ImgMute = styled.img`
  margin: .5rem 0 .5rem .5rem;
  width: 24px;
  background: red;
  padding: 0.1rem;
  border-radius: 5px;
`;

const renderLegend = (isMuted, peer) => {
  return (
    <ContainerLegend>
    {isMuted.map(item => <ImgMute src={IconMute} alt="Mute"/>)}
    <LegendPeer isMuted={isMuted[0]}>{peer}</LegendPeer>
  </ContainerLegend>
  )
}

const PeerVideo = ({ media, peer }) => {
  const videoStreams = media.filter(
    (m) => m.kind === "video" && !m.remoteDisabled
  );

  const audioStreams = media.filter((m) => m.kind === "audio" && m.remoteDisabled);
  const isMuted = audioStreams.map(item => item.remoteDisabled)

  const peerSplit = peer.split(' ');
  const userType = peerSplit.pop();

  if (videoStreams.length > 0) {
    // Choose last media as it is most likely the screenshare.
    const webcamStreams = videoStreams.filter((s) => !s.screenCapture);
    const screenCaptureStreams = videoStreams.filter(s => s.screenCapture);
    return (
      <>
        {
          screenCaptureStreams.length > 0 ? (
            <Video media={screenCaptureStreams[0]} />
          ) : (
              <Video media={webcamStreams[0]} />
            )
        }
        {renderLegend(isMuted, peer)}
      </>
    )
  }
  return (
    <>
      <ContainerNoVideo>
        <NoVideo src={userType === 'Médico' ? IconNoVideoDoctor : IconNoVideo} />
        {renderLegend(isMuted, peer)}
      </ContainerNoVideo>
    </>
  )
};

const renderBoxVideo = (
  media,
  peer,
  size,
  maximized,
  setMaximized,
  showVolume,
  setShowVolume,
  isToolbar,
  setIsToolbar,
  setIsFullscreen,
  isFullscreen,
  errorFullScreen,
  maximizableElement,
) => (
  <BoxVideo
    key={peer.id}
    size={size}
    maximized={maximized}
    address={peer.address}
    onMouseLeave={() => setIsToolbar({ isOpenOptions: undefined, type: '', isModal: false })}
    onMouseEnter={() => setIsToolbar({ isOpenOptions: true, type: peer.address })}
  >
    <VideoContainer ref={(node) => maximizableElement && maximizableElement.push({
      current: node,
      peer: peer.id,
    })}>
      {/* videoOnly={videoOnly} */}
      <PeerVideo media={media} peer={peer.displayName} />
    </VideoContainer>
      <Toolbar>
        <PeerControls
          peer={peer}
          setVolumeLimit={() => 1}
          render={({ peer, setVolumeLimit, mute, unmute }) => {
            return (
              <>
                {isToolbar.isOpenOptions
                  && isToolbar.type === peer.address
                  && <IconOption
                      src={Icon}
                      alt="options"
                      onClick={() => setIsToolbar({ ...isToolbar, isOpenOptions: false, isModal: true })}
                    />}
                {isToolbar.isModal && isToolbar.type === peer.address && (
                  <>
                    {!errorFullScreen && maximizableElement && (
                      <ToolbarButton
                        onClick={() => {
                          const elFull = maximizableElement.find(el => el.peer === peer.id)
                          setIsFullscreen(elFull)
                          setIsToolbar(false);
                        }}
                      >
                        Fullscreen
                      </ToolbarButton>
                    )}
                    <ToolbarButton
                      onClick={() => {
                        const maximize = maximized === peer.address ? "" : peer.address;
                        setMaximized(maximize);
                        setIsToolbar(false);
                      }}
                    >
                      {maximized === peer.address ? "Minimizar" : "Maximizar"}
                    </ToolbarButton>
                    <ToolbarButton
                      onClick={() => {
                        showVolume === peer.address
                          ? setShowVolume("")
                          : setShowVolume(peer.address)
                      }}
                    >
                      {showVolume === peer.address
                        ? "Esconder Volume"
                        : "Mostrar Volume"}
                    </ToolbarButton>
                    {showVolume === peer.address ? (
                      <input
                        type="range"
                        min="0"
                        max="10"
                        value={peer.volumeLimit * 10}
                        step="1"
                        onChange={(event) => {
                          const volume = 
                            event.target.value === 0 ? 0 : event.target.value / 10;

                            if(volume === 0) {
                              mute()
                              setVolumeLimit(volume);
                            } else {
                              unmute()
                              setVolumeLimit(volume);
                            }
                        }}
                      />
                    ) : null}
                  </>
                )}
              </>
            );
          }}
        />
      </Toolbar>
    </BoxVideo>
  );

const renderPeerMedia = (
  peer,
  size,
  maximized,
  setMaximized,
  showVolume,
  setShowVolume,
  isToolbar,
  setIsToolbar,
  setIsFullscreen,
  isFullscreen,
  errorFullScreen,
  maximizableElement,
) => {
  if (peer && peer.address) {
    return (
      <RemoteMediaList
        audio={true}
        video={true}
        peer={peer.address}
        render={({ media }) =>
          renderBoxVideo(
            media,
            peer,
            size,
            maximized,
            setMaximized,
            showVolume,
            setShowVolume,
            isToolbar,
            setIsToolbar,
            setIsFullscreen,
            isFullscreen,
            errorFullScreen,
            maximizableElement,
          )
        }
      />
    )
  }
};

const renderPeers = (
  peers,
  size,
  maximized,
  setMaximized,
  toggleChat,
  showVolume,
  setShowVolume,
  isToolbar,
  setIsToolbar,
  displayName,
  userType,
  setIsFullscreen,
  isFullscreen,
  errorFullScreen,
  maximizableElement,
) => (
  <>
    {maximized && peers.length > 0 ? (
      <Maximized>
        {renderPeerMedia(
          peers.filter((peer) => peer.address === maximized)[0],
          size,
          maximized,
          setMaximized,
          showVolume,
          setShowVolume,
          isToolbar,
          setIsToolbar,
          displayName,
          setIsFullscreen,
          isFullscreen,
          errorFullScreen,
          maximizableElement,
        )}
      </Maximized>
    ) : null}
    <Peers maximized={maximized} size={size}>
      {peers.map((peer) =>
        maximized === peer.address
          ? null
          : renderPeerMedia(
              peer,
              size,
              maximized,
              setMaximized,
              showVolume,
              setShowVolume,
              isToolbar,
              setIsToolbar,
              setIsFullscreen,
              isFullscreen,
              errorFullScreen,
              maximizableElement,
            )
        )}
        <LocalMediaList
          audio={false}
          video={true}
          render={({ media }) => (
            <BoxVideo size={size} maximized={maximized}>
              <Me media={media} toggleChat={toggleChat} userType={userType}/>
              <Legend>{displayName}</Legend>
            </BoxVideo>
          )}
        />
      </Peers>
    </>
  );

export default ({
  size,
  setSize,
  maximized,
  setMaximized,
  room,
  toggleChat,
  userType,
  displayName,
}) => {
  const maximizableElement = [];
  let isFullscreen, setIsFullscreen;
  let errorFullScreen;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorFullScreen = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  const [showVolume, setShowVolume] = useState("");
  const [isToolbar, setIsToolbar] = useState({ isOpenOptions: undefined, type: '', isModal: undefined });

  return (
    <PeerContainer>
      <PeerList
        room={room}
        render={({ peers }) => {
          setSize(peers.length + 1);
          if (peers.length > 0) {
            return renderPeers(
              peers,
              size,
              maximized,
              setMaximized,
              toggleChat,
              showVolume,
              setShowVolume,
              isToolbar,
              setIsToolbar,
              displayName,
              userType,
              setIsFullscreen,
              isFullscreen,
              errorFullScreen,
              maximizableElement,
            );
          }
          return (
            <LocalMediaList
              audio={false}
              video={true}
              render={({ media }) => (
                <>
                  <BoxVideo size={size} maximized={maximized}>
                    <Me media={media} toggleChat={toggleChat} userType={userType} />
                    <Legend>{displayName}</Legend>
                  </BoxVideo>
                  <BoxVideo size={size} maximized={maximized}>
                    <BoxVideoGuestText>Aguardando convidado...</BoxVideoGuestText>
                  </BoxVideo>
                </>
              )}
            />
          )
        }}
      />
    </PeerContainer>
  );
};
