import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";
import queryString from "query-string";

const mapStateToProps = state => ({
  auth: state.auth
});

class Private extends Component {
  render() {
    const { auth, children, location } = this.props;

    const query = queryString.parse(location.search);
    const userType = location.pathname.split("/")[2];

    return (auth.token || query.token) ? (
      <Route
        render={props => {
          return (
            React.cloneElement(
              children,
              {
                ...this.props,
                tokenQuery: query.token,
                showVideoOnly: query.showVideoOnly,
                showChatOnly: query.showChatOnly,
                username: query.username,
                userType: userType,
              }
            )
          )
        }}
      />
    ) : (
      <Redirect
        to={{
          pathname: "/notfound",
          state: { from: location }
        }}
      />
    );
  }
}

export default withRouter(connect(mapStateToProps)(Private));
