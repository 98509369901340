// Action Types
export const IS_FETCHING = "sirio/authentication/IS_FETCHING";
export const SAVE_TOKEN_CALL = 'sirio/authentication/SAVE_TOKEN_CALL';
export const SAVE_ACCESS_TOKEN = 'sirio/authentication/SAVE_ACCESS_TOKEN';
export const LOAD_USER_DATA = 'sirio/authentication/LOAD_USER_DATA';

export const initialState = {
    isFetching: false,
    token: null,
    roomAccess: {},
    user: {
      userName: '',
      type: '',
    }
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return Object.assign({}, state, {
        isFetching: true
      });
    case SAVE_TOKEN_CALL:
      return Object.assign({}, state, {
        token: action.info.token
      });
    case SAVE_ACCESS_TOKEN:
      return Object.assign({}, state, {
        roomAccess: {...state.roomAccess, ...action.info},
      })
    case LOAD_USER_DATA:
      return Object.assign({}, state, {
        user: action.info,
      });
    default:
      return state;
  }
};

// Action Creators
export const saveTokenCall = info => ({
  type: SAVE_TOKEN_CALL,
  info,
});

export const saveAccessToken = info => ({
  type: SAVE_ACCESS_TOKEN,
  info,
});

export const loadUserData = info => ({
  type: LOAD_USER_DATA,
  info,
})