import React, { Component } from "react";
import { UserControls, RequestDisplayMedia, MediaControls } from "@andyet/simplewebrtc";
import styled, { keyframes } from 'styled-components';
import { connect } from "react-redux";

//Image
import CameraOn from '../../assets/cameraOn.svg';
import CameraOff from '../../assets/cameraOff.svg';
import IconAudio from '../../assets/audio.svg';
import IconMuted from '../../assets/muted.svg';
import IconChat from '../../assets/chat.svg';
import Share from '../../assets/share.svg';
import IconEndCall from '../../assets/endCall.svg';

//Redux
import { isOpenMSG } from '../../dataflow/modules/chat-module';

const DarkBlue = '#0B4966';

const mapStateToProps = state => ({
  isWriting: state.chat.isWriting,
  isNotification: state.chat.isNotification,
  isRead: state.chat.isRead,
  isOpenMSG: state.chat.isOpenMSG,
  media: state.simplewebrtc.media,
  chats: state.simplewebrtc.chats,
});

const Controls = styled.div`
  padding-top: 1rem;
  min-width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 667px) {
    min-width: 250px;
  }
`;

const ContainerButton = styled.button`
  margin: 0 1.45rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;

  @media (max-width: 920px) {
    margin: 0 0.35rem;
  }
`;
const ShareButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;

  @media (max-width: 920px) {
    display: none;
  }
`;

const Figure = styled.figure`
  position: relative;
  margin-bottom: .8rem;
  background: ${props => props.background ? '#DA3838' : DarkBlue};
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 1rem;
  border: none;
  display: flex;
  justify-content: center;
  
  @media (max-width: 667px) {
    width: 3.75rem;
    height: 3.75rem;
  }
`;

const Icon = styled.img`
  width: 37px;
`;

const Figcaption = styled.figcaption`
  color: #FFFFFF;
  font: 600 1rem 'Opens Sans', sans-serif;
`;

const ChatAlertAnimation = keyframes`
	0%{ opacity: 0.3 }
	75%{ opacity: 1 }
	100%{ opacity: 0.3 }
`;

const Notification = styled.span`
  background: #DA3838;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  top: -6px;
  right: -5px;
  animation: ${ChatAlertAnimation} 1.5s linear infinite;
`;

class UserControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMuted: undefined,
      isPaused: undefined,
      redirect: undefined,
    }
  }

  match = event => event.key.toLowerCase();

  onUp = event => {
    if (!this.props.isWriting) {
      if (this.match(event) === 'm') {
        document.querySelector('#idMuted').click();
      }
      if (this.match(event) === 'v') {
        document.querySelector('#idVideo').click();
      }
      if (this.match(event) === 'c') {
        document.querySelector('#idChat').click();
      }
    }
  }

  componentDidMount() {
    window.addEventListener("keyup", this.onUp);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.onUp)
  }

  handleUnMuted = () => {
    this.setState({
      isMuted: false,
    })
  }

  handleMute = () => {
    this.setState({
      isMuted: true,
    })
  }

  handleEndCall = () => {
    const { pathname } = this.props.history.location;

    localStorage.removeItem('passToken');
    localStorage.removeItem('passRoom');

    const route = pathname.split("/");
    const room = route[3];

    this.props.history.push({
      pathname: `/atendimento/${room}`,
    });
  }

  render() {
    const { video, isMuted, isPaused } = this.state;
    const chats = Object.keys(this.props.chats);

    return (
      <UserControls
        isMuted={isMuted}
        isPaused={isPaused}
        unmute={this.handleMuted}
        mute={this.handleMute}
        render={(props) => {
          return (
            <Controls>
              <ContainerButton>
                <Figure
                  background={video}
                  id='idVideo'
                  onClick={() => {
                    this.setState({ video: !props.isPaused })
                    !props.isPaused ? props.pauseVideo() : props.resumeVideo()
                  }}>
                  <Icon src={props.isPaused ? CameraOff : CameraOn} alt="Câmera" />
                </Figure>
                <Figcaption>Câmera</Figcaption>
              </ContainerButton>
              <ContainerButton>
                <Figure
                  background={isMuted}
                  id='idMuted'
                  onClick={() => {
                    this.setState({ isMuted: !props.isMuted })
                    !props.isMuted
                      ? props.mute()
                      : props.unmute()
                  }}>
                  <Icon src={props.isMuted ? IconMuted : IconAudio} alt="Microfone" />
                </Figure>
                <Figcaption>Microfone</Figcaption>
              </ContainerButton>
              {this.props.userType === 'medico' && (
                <RequestDisplayMedia
                  audio
                  render={(getDisplayMedia, sharing) => {
                    var myKeys = Object.values(this.props.media)
                    const screenCapture = myKeys.filter(m => m.screenCapture);
                    const latestCapture = screenCapture[screenCapture.length - 1];

                    if (screenCapture.length > 0 && latestCapture.source === 'local') {
                      return (
                        <MediaControls
                          media={latestCapture}
                          autoRemove={true}
                          render={({ stopSharing }) => (
                            <>
                              <ShareButton onClick={stopSharing}>
                                <Figure>
                                  <Icon src={Share} alt="Parar Compartilhamento" />
                                </Figure>
                                <Figcaption>Parar Compartilhamento</Figcaption>
                              </ShareButton>
                            </>
                          )}
                        />
                      )
                    }

                    if (sharing.available) {
                      return (
                        <ShareButton onClick={getDisplayMedia}>
                          <Figure>
                            <Icon src={Share} alt="Compartilhar tela" />
                          </Figure>
                          <Figcaption>Compartilhar Tela</Figcaption>
                        </ShareButton>
                      )
                    }
                  }}
                />
              )}
              <ContainerButton>
                <Figure id='idChat' onClick={this.props.toggleChat}>
                  <Icon src={IconChat} alt="Chat" />
                  {((chats.length === 1 && !this.props.isRead) || (chats.length > 1 && this.props.isNotification)) && (!this.props.isOpenMSG) && <Notification />}
                </Figure>
                <Figcaption>Chat</Figcaption>
              </ContainerButton>
              <ContainerButton>
                <Figure background onClick={this.handleEndCall}>
                  <Icon src={IconEndCall} alt="Encerrar" />
                </Figure>
                <Figcaption>Encerrar</Figcaption>
              </ContainerButton>
            </Controls>
          );
        }
        }
      />
    )
  }
}

export default connect(mapStateToProps, null)(UserControl);
