import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";

//Import
import Logo from '../assets/sirio_logo.png';
import Help from '../assets/help.svg';
import ToInvite from '../assets/toInvite.svg';
import ModalToInvite from '../components/modalToInvite';
import PeoplesWhite from '../assets/peoplesWhite.svg';

const mapStateToProps = state => ({
	token: state.auth.token,
	roomAccess: state.auth.roomAccess,
	user: state.simplewebrtc.user,
	peers: state.simplewebrtc.peers,
	media: state.simplewebrtc.peers,
});

// Styles
const Container = styled.div`
	position: relative;
  display: flex;
  justify-content: center;
	align-items: ${props => props.login ? 'flex-end' : 'center'};
  width: 100%;
  height: 75px;
  padding: 0 3.375rem;
  text-align: center;

	@media (max-width: 648px) {
		align-items: center;
		padding: 0 0.813rem;
  }
`;

const ImgLogo = styled.img`
  height: 45px;
	position: absolute;
	left: 3.375rem;

	@media(max-width: 648px) {
		left: 1.3rem;
	}
`;

const Title = styled.h1`
  color: #fff;
  font: 600 1.25rem "Open Sans", serif;

  @media (max-width: 648px) {
		display: none;
  }
`;

const ContainerButtons = styled.div`
	display: flex;
	position: absolute;
	right: 3.375rem;

	@media(max-width: 648px) {
		right: 1rem;
	}
`;

const ContainerPeoples = styled.div`
	width: 70px;
	display: flex;
	align-items: center;

	@media(max-width: 648px) {
		width: 60px;
	}
`;

const PeoplesIcon = styled.img`
	margin-right: .5rem;
	width: 1.2rem;
`;

const Button = styled.button`
	display: ${(props) => (props.display ? "none" : "flex")};
	margin-left: 0.6875rem;
  width: 138px;
	height: 42px;	
  background: #0B4966;
  align-items: center;
  border-radius: 21px;
	border: none;
  padding: .0 .6rem;
	cursor: pointer;

  @media (max-width: 900px) {
		display: ${(props) => (props.displayMob  ? "none" : "flex")};
  }

	@media(max-width: 648px) {
    width: 45px;
    justify-content: center;
	}
`;

const Icon = styled.img`
	margin-right: 17px;

	@media (max-width: 648px) {
	 margin-right: 0;
 }
`;

const Overlay = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	bottom: 0;
	background: transparent;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ContainerModal = styled.div`
	margin: ${props => props.login ? '5.4rem 0 0' : '4.4rem 0 0'};
	position: absolute;
	top: 0;
	bottom: 0;
	right: 3.375rem;
	width: 311px;
	height: 159px;
	border-radius: 5px;
	z-index: 11;
	background: #FFF;

	@media (max-width: 648px) {
		margin: 4.3rem 0 0;
		right: .813rem;
  }

	@media (max-width: 322px) {
		width: 291px;
		right: .813rem;
  }
`;

const ContainerKeyboard = styled.div`
	padding: .5rem .688rem;
	display: flex;
	justify-content: space-between;
`;

const Text = styled.p`
	${(props) => (props.title ? "padding : .5rem 0" : null)};
	${(props) => (props.title ? "background : #0B4966" : null)};
	${(props) => (props.title ? "border-radius: : 0.5rem 0.5rem 0 0" : null)};
	color: ${props => props.color ? '#fff' : '#000'};
	font: 500 1rem "Open Sans", serif;
	
	@media (max-width: 648px) {
  	${(props) => (props.display ? "display : none" : null)};
		font-size: 0.875rem;
  }
`;

const KeyboardKey = styled.p`
	color: #0B4966;
  font: 500 1rem "Open Sans", serif;
  text-transform: uppercase;
`;

const Border = styled.div`
	padding: ${props => props.border === 'm' ? '0 4px' : '0 6px'};
	border-radius: 5px;
	border: 1px solid #0B4966;
`;

const ModalTitle = styled.h1`
	padding: ${props => (props.title) && '.56rem'};
	font: 600 1rem "Open Sans", serif;
	font-weight: ${props => (props.selectedOption || props.title) ? '600' : '400'};
	color: ${props => (props.title) ? '#FFF' : '#0B4966'};
	background: ${props => (props.title) && '#0B4966'};
	border-radius: 5px 5px 0 0;
`;

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalToInvite: false,
			isModalHelp: false,
			buttons: [
				{
					icon: ToInvite,
					text: 'Convidar',
				},
				{
					icon: Help,
					text: 'Ajuda',
				}
			],
			keyboardDetail: [
				{
					text: 'Iniciar ou parar sua câmera',
					key: 'v',
				},
				{
					text: 'Ativar ou desativar seu microfone',
					key: 'm',
				},
				{
					text: 'Abrir ou fechar chat',
					key: 'c',
				},
			]
		}
	}

	handleClick = (item) => {
		if (item === 'Convidar') {
			this.setState({
				isModalToInvite: !this.state.isModalToInvite,
				isModalHelp: false,
			});
		} else {
			this.setState({
				isModalHelp: !this.state.isModalHelp,
			})
		}
	}

	handleModalToInvite = (event) => {
		event.stopPropagation();
		this.setState({
			isModalToInvite: false,
		})
	}

	isModalHelp = () => {
		this.setState({
			isModalHelp: false,
		})
	}

	renderModal = () => (
		<ContainerModal login={this.props.login}>
			<ModalTitle title>Atalhos de teclado</ModalTitle>
			{this.state.keyboardDetail.map(item => {
				return (
					<ContainerKeyboard key={item.key}>
						<Text>{item.text}</Text>
						<Border border={item.key}>
							<KeyboardKey>{item.key}</KeyboardKey>
						</Border>
					</ContainerKeyboard>
				)
			})}
		</ContainerModal>
	)

	renderButtons = () => {
		return this.state.buttons.map(item => {
			return (
				<Button key={item.text} onClick={() => this.handleClick(item.text)} display={this.props.login && item.text === 'Convidar'} displayMob={item.text === 'Ajuda' || this.props.login}>
					<Icon src={item.icon} alt="Icone de ajuda" />
					<Text color display>{item.text}</Text>
				</Button>
			)
		})
	}

	render() {
		const { isModalHelp, isModalToInvite } = this.state;
		const { peers, displayName, username, userType } = this.props;
		
		return (
			<>
				<Container login={this.props.login}>
					<ImgLogo src={Logo} alt="Hospital Sírio Libanês" />
					<Title>
						Teleconsulta
					</Title>
					<ContainerButtons>
						{!this.props.login &&
							<ContainerPeoples>
								<PeoplesIcon src={PeoplesWhite} alt='Pessoas na Sala' />
								{Object.keys(this.props.media) &&
									<Text color>{Object.keys(this.props.media).length + 1} / 6</Text>
								}
							</ContainerPeoples>
						}
						{this.renderButtons()}
					</ContainerButtons>
				</Container>
				{isModalHelp && <Overlay onClick={this.isModalHelp} />}
				{isModalHelp && this.renderModal()}
				{isModalToInvite &&
					<ModalToInvite
						peers={peers}
						displayName={displayName}
						roomAccess={this.props.roomAccess}
						handleModalToInvite={this.handleModalToInvite}
						username={username}
						userType={userType}
					/>
				}
			</>
		);
	}
}

export default connect(mapStateToProps, null)(Header);
