import React, { useState } from "react";
import styled from "styled-components";

import VideoList from "./VideoList";

const calcWidth = (size, type, maxi) => {
  if (type === "desktop") {
    if (size <= 4) {
      if (maxi) {
        return "20%";
      }
      return "48%";
    }
    if (size >= 5) return "30%";
  }
  if (type === "mobile") {
    if (size <= 2) return "85%";
    if (size >= 3) return "48%";
    if (size >= 5) return "35%";
  }
};

const calcHeight = (size, type, maxi) => {
  if (type === "desktop") {
    if (size <= 2) {
      if (maxi) {
        return "40%";
      }
      return "100%";
    }
    if (size >= 3) {
      if (maxi) {
        return "30%";
      }
      return "48%";
    }
  }
  if (type === "mobile") {
    if (size <= 2) return "50%";
    if (size <= 4) return "48%";
    if (size >= 5) return "32%";
  }
};

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  max-width: 1800px;
  max-height: 1000px;
  width: ${(props) => (props.size >= 3 && props.size < 5 ? "75%" : "95%")};
  height: ${(props) => (props.size >= 4 ? "90%" : "80%")};

  @media (max-width: 920px) {
    height: 80%;
    width: ${(props) => (props.size >= 3 && props.size < 5 ? "95%" : "100%")};
  }
`;

export const BoxVideo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0b4966;
  border-radius: 8px;
  overflow: hidden;
  width: ${(props) => calcWidth(props.size, "desktop", props.maximized)};
  height: ${(props) => calcHeight(props.size, "desktop", props.maximized)};

  @media (max-width: 667px) {
    width: ${(props) => calcWidth(props.size, "mobile")};
    height: ${(props) => calcHeight(props.size, "mobile")};
  }
`;

export const Legend = styled.p`
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0 4px;
  background-color: rgba(11, 73, 102, 0.7);
  font: Regular 16px/16px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  width: 100%;
  margin: 0;
  padding: 0.25rem 1rem;
`;

export default (props) => {
  const { roomAddress, toggleChat, displayName, userType } = props;

  const [size, setSize] = useState();
  const [maximized, setMaximized] = useState("");

  return (
    <Container size={size} className="video-call-container">
      <VideoList
        size={size}
        setSize={setSize}
        maximized={maximized}
        setMaximized={setMaximized}
        room={roomAddress}
        toggleChat={toggleChat}
        displayName={displayName}
        userType={userType}
      />
    </Container>
  );
};
