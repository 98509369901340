import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "axios";

//Component
import Header from "../../components/header";

// Redux
import { saveTokenCall } from "../../dataflow/modules/authentication-module";

const mapDispatchToProps = (dispatch) => ({
  saveTokenCall: (info) => dispatch(saveTokenCall(info)),
});

// Styles
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #0561af;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
`;

const Form = styled.form`
  max-width: 420px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  outline: none;

  @media (max-width: 648px) {
    padding: 0 2.43rem;
    height: 100%;
    justify-content: space-around;
  }
`;

const Title = styled.label`
  display: none;

  @media (max-width: 648px) {
    display: flex;
    font: 600 1rem Open Sans;
    color: #ffffff;
  }
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5.188rem;

  @media (max-width: 648px) {
    margin-bottom: 4.188rem;
  }
`;

const Label = styled.label`
  font: 500 1.25rem Open Sans;
  color: ${(props) => (props.error ? "#00FFF8" : "#FFFFFF")};

  @media (max-width: 648px) {
    font-size: 0.875rem;
  }
`;

const Input = styled.input`
  width: 84%;
  border: none;
  background: none;
  font: 500 2.188rem Open Sans;
  color: ${(props) => (props.error ? "#00FFF8" : "#FFFFFF")};
  letter-spacing: ${(props) => props.passtoken && "1.8rem"};
  outline: none;
  padding-left: 3.875rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    font-weight: 300;
    color: #ffffff;
    opacity: 0.4;
  }

  @media (max-width: 648px) {
    width: 80%;
    font-size: 1.5rem;
    padding-left: 2rem;
  }
`;

const Border = styled.span`
  width: 100%;
  border: 1.5px solid ${(props) => (props.error ? "#00FFF8" : "#69a0cf")};
  border-radius: 2px;
  box-shadow: 0px 3px 3px #00000014;
`;

const Footer = styled.div`
  width: 100%;
`;

const Button = styled.button`
  padding: 1.2rem;
  width: 100%;
  background: #0b4966;
  border-radius: 2rem;
  border: none;
  cursor: pointer;

  @media (max-width: 648px) {
    padding: 0.8rem;
  }
`;

const TextButton = styled.p`
  font: 600 1.25rem Open Sans;
  color: #ffffff;

  @media (max-width: 648px) {
    font-size: 0.875rem;
  }
`;

const Error = styled.p`
  text-align: center;
  font: 300 1rem Open Sans;
  font-size: ${(props) => props.typeError && "1.25rem"};
  color: #fff;
  padding-bottom: 0.5rem;
  text-align: center;

  @media (max-width: 648px) {
    font-size: ${(props) => (props.typeError ? " 1rem" : " 0.875rem")};
  }
`;

class PassToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      username: "",
      errorName: undefined,
      errorPasstoken: undefined,
      status: undefined,
    };
  }

  validateToken = async (token) => {
    const room = this.props.match.params.roomId;

    axios
      .get(`${process.env.REACT_APP_PASS_CODE_URL}/${room}/${token}`)
      .then((response) => {
        this.props.saveTokenCall({
          token: response.data.token,
        });
        if (response.data.type === 'patient') {
          localStorage.setItem('passToken', token);
          localStorage.setItem('passRoom', room);
          this.props.history.push({
            pathname: `/atendimento/paciente/${room}`,
            state: {
              username: this.state.username,
              type: response.data.type,
            },
          });
        } else {
          this.props.history.push({
            pathname: `/atendimento/medico/${room}`,
            state: {
              username: this.state.username,
              type: response.data.type,
            },
          });
        }
        this.setState({
          status: "",
        });
      })
      .catch((err) => {
        console.log("error", err.response);
        if (err.response.data === "Couldn't fetch the todo item.") {
          this.setState({
            errorPasstoken: true,
            status: undefined,
          });
        } else {
          this.setState({
            error: "token_unvalible",
            errorPasstoken: true,
            status: "Não é possível iniciar a conversa",
          });
        }
      });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { token, username } = this.state;

    if (username.length < 3) {
      this.setState({
        errorName: true,
      });
    } else if (token.length < 4) {
      this.setState({
        errorPasstoken: true,
      });
    } else {
      this.validateToken(token);
    }
  };

  handleChange = (field, ev) => {
    if (field === "username") {
      this.setState({
        errorName: ev.target.value.length < 3,
      });
    }

    this.setState({
      [field]: ev.target.value,
    });
  };

  render() {
    const { errorName, token, errorPasstoken, error, status } = this.state;

    return (
      <Container>
        <Header login />
        <Content>
          <Form onSubmit={this.handleSubmit} error={error}>
            <Title>Teleconsulta</Title>
            <div>
              <FormBox>
                <Label error={errorName}>
                  Nome
                  <Input
                    type="text"
                    placeholder="Inserir nome"
                    onChange={(ev) => this.handleChange("username", ev)}
                    error={errorName}
                  />
                </Label>
                <Border error={errorName} />
                {errorName && <Error typeError>Insira um nome válido</Error>}
              </FormBox>
              <FormBox>
                <Label error={errorPasstoken}>
                  Senha
                  <Input
                    passtoken
                    type="number"
                    name="passtoken"
                    placeholder="0000"
                    value={token}
                    onChange={(ev) => this.setState({ token: ev.target.value.length <= 4 ? ev.target.value : token })}
                    error={errorPasstoken}
                  />
                </Label>
                <Border error={errorPasstoken} />
                {errorPasstoken && <Error typeError>Senha incorreta</Error>}
              </FormBox>
            </div>
            <Footer>
              {status && <Error>{status}</Error>}
              <Button>
                <TextButton>Iniciar atendimento virtual</TextButton>
              </Button>
            </Footer>
          </Form>
        </Content>
      </Container>
    );
  }
}

export default connect(null, mapDispatchToProps)(PassToken);
