import React from "react";
import { Video } from "@andyet/simplewebrtc";
import styled from 'styled-components';

//Image
import IconNoVideo from "../../assets/pacient.svg";
import IconNoVideoDoctor  from "../../assets/doctor.svg";

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 667px) {
    position: static;
    transform: translate(0);
  }
`;

const ContainerNoVideo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoVideo = styled.img`
  width: 5rem;
`;

export default ({ media, userType }) => {
  const videoStreams = media.filter(m => m.kind === "video");
  const latestVideo = videoStreams[videoStreams.length - 1];
  const isVideo = media.filter(m => m.kind === "video" && !m.localDisabled );

  if (isVideo.length > 0) {
    return (
      <Container>
        <Video media={latestVideo} />
      </Container>
    );
  }
  return (
    <ContainerNoVideo>
      <NoVideo src={userType === 'medico' ? IconNoVideoDoctor : IconNoVideo} />
    </ContainerNoVideo>
  )
};
