import React from 'react';
import styled from 'styled-components';

// Styles
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #0561af;

  @media (max-width: 648px) {
    padding: .8rem;
  }
`;

const Message = styled.p`
  color: #fff;
  font: 400 1.5rem 'Open Sans', serif;
  text-align: center;
`;

const NotFound = () => (
  <Container>
    <Message>
      Esta sala não existe, verifique o código e tente novamente.
    </Message>
  </Container>
);

export default NotFound;