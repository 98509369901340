import React, { Component } from "react";
import styled from "styled-components";

import {
  RequestUserMedia,
  RemoteAudioPlayer,
} from "@andyet/simplewebrtc";

import VideoCall from "./VideoCall";
import UserControls from "./UserControls";
import Chat from "./chat";

const GridContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 75px);
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-direction: 'column';

  @media (max-width: 667px) {
    width: 100%;
  }
`;

const InnerGrid = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  width: 90%;
  height: calc(100% - 25px);
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  width: ${(props) => (props.isChatOpened ? "75%" : "90%")};

  @media (max-width: 667px) {
    width: 100%;
    height: 100%;
  }
`;

class VideoContainer extends Component {
  render() {
    const { displayName, userType, roomId, room, sendRtt, history, isChatOpened, toggleChat } = this.props;

    return (
      <GridContainer>
        <InnerGrid>
          <RequestUserMedia audio video share auto />
          <RemoteAudioPlayer />
          <VideoCall
            {...this.props}
            roomAddress={room.address}
            roomId={roomId}
            toggleChat={toggleChat}
            displayName={displayName}
            userType={userType}
          />
          <UserControls
            toggleChat={toggleChat}
            history={history}
            userType={userType}
          />
        </InnerGrid>
        {
          isChatOpened && (
            <Chat
              {...this.props}
              toggleChat={toggleChat}
              sendRtt={sendRtt}
              roomAddress={room.address}
              displayName={displayName}
              hasVideo
              isChatOpened={isChatOpened}
            />
          )
        }
      </GridContainer>
    );
  }
}

export default VideoContainer;
