// Libs
import React, { Component } from "react";
import styled from "styled-components";
import { format } from 'date-fns';
import { connect } from "react-redux";
import { values } from "lodash";

//Redux
import {isNotification, isRead} from '../../dataflow/modules/chat-module.js';

const mapStateToProps = state => ({
  isOpenMSG: state.chat.isOpenMSG,
  chatsRedux: state.simplewebrtc.chats,
});

const mapDispatchToProps = dispatch => ({
  isNotification: info => {
    dispatch(isNotification(info));
  },
  isRead: info => {
    dispatch(isRead(info));
  }
})

// Styles
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.75rem auto;
  padding: 0 1.5rem 0 2.5rem;

  @media (max-width: 677px) {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0 1%;
    justify-content: flex-end;
  }
`;

const MessageName = styled.p`
  display: ${({ me }) => me ? 'none' : 'flex'};
  color: #8A8A8A;
  font: 500 .65rem "Open Sans", sans-serif;

  span {
    margin-left: 4px;
    font-weight: 300;
  }
`;

const Message = styled.div`
  position: relative;
  max-width: 90%;
  margin: 6px 0;
  padding: .5rem 4rem .5rem 1rem;
  border-radius: 8px;
  background: ${props => (props.me ? '#fff' : '#A3E6FF')};
  align-self: ${({ me }) => me ? 'flex-end' : 'flex-start'};
`;

const MessageDate = styled.span`
  position: absolute;
  right: .5rem;
  color: #4D4C4F;
  font: 300 .75rem "Open Sans", sans-serif;
`;

const MessageText = styled.span`
  display: block;
  color: #4D4C4F;
  font: 400 .8rem "Open Sans", sans-serif;
  word-break: break-word;

  @media(max-width: 900px) {
    font-size: .95rem;
  }

  @media(max-width: 648px) {
    font-size: .8rem;
  }
`;

class Messages extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }

  componentDidUpdate(oldProps) {
    const { scrollbar, chats, isOpenMSG, isNotification } = this.props;
    const arrayMSG =  values(this.props.chatsRedux);
    const oldArrayMSG =  values(oldProps.chatsRedux);

    if (oldProps.chats.length !== chats.length - 1) {
      scrollbar.scrollTop = scrollbar.scrollHeight;
    }

    if (arrayMSG.length !== oldArrayMSG.length && isOpenMSG === false) {
      isNotification( true );
      this.props.isRead(false);
    };
  }

  renderName = () => {
    const { displayName, chats } = this.props;
    const me = chats[0].displayName === displayName;
    const nameSplit = chats[0].displayName.split(' ');
    const nameUser = nameSplit.shift()
    const typeUser = nameSplit.pop();

    if (chats[0].displayName) {
      return <MessageName me={me}>{nameUser} <span> - {typeUser}</span></MessageName>
    } else {
      return <MessageName me={me}> Anônimo </MessageName>
    }
  }

  render() {
    const { displayName, chats, hasVideo } = this.props;
    const me = chats[0].displayName === displayName;

    return (
      <MessageContainer
        hasVideo={hasVideo}
        key={chats[0].id}
      >
        {this.renderName()}
        {chats.map(message => {
          return (
            <Message key={message.id} me={me}>
              <MessageDate>{format(message.time, 'HH:mm')}</MessageDate>
              <MessageText>{message.body}</MessageText>
            </Message>
          )
        })}
      </MessageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
