export const formatType = (typeUser) => {
  if (typeUser === 'patient' || typeUser === 'paciente') return 'Paciente';
  if (typeUser === 'doctor' || typeUser === 'medico') return 'Profissional de Saúde';
  return 'Convidado';
}

export const formatName = (location, username, userType) => {
  if (location.state) {
    return `${location.state.username} - ${formatType(userType)}`
  }
  if (username) {
    return `${username} - ${formatType(userType)}`;
  }
  return `Convidado - ${formatType(userType)}`;
}
