import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { values } from "lodash";
import jwt from 'jsonwebtoken';

//Import
import Close from '../assets/close-white.svg';
import Copy from '../assets/copy.svg';

const mapStateToProps = state => ({
	token: state.auth.token,
	roomAccess: state.auth.roomAccess,
	user: state.simplewebrtc.user,
	peers: state.simplewebrtc.peers,
});

// Styles
const Overlay = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	bottom: 0;
	background: #44444482;
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ContainerInvite = styled.div`
	width: 26.5rem;
	border-radius: 5px;

	@media(max-width: 648px) {
		width: 23rem;
	}

	@media(max-width: 380px) {
		padding: 1rem;
	}
`;

const HeaderInvite = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 .7rem 0 1.125rem;
  background: #0B4966;
	border-radius: 5px 5px 0 0;
`;

const ButtonIcon = styled.img`
  width: 30px;
	cursor: pointer;
`;

const SubheaderInvite = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	padding: 0 2.5%;
	background: #fff;
	border-bottom: 0.2px solid #8a8a8a14;
`;

const ButtomTypeInvite = styled.button`
	position: relative;
	width: auto;
	height: 38px;
  display: ${props => (props.type) ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
	flex-direction: column;
  background: #FFF;
  border: none;
  outline: none;
  cursor: pointer;
`;

const BorderInvite = styled.span`
	position: absolute;
	border: 1.5px solid #0B4966;
	border-radius: 3px;
	bottom: 0;
	width: 120%;
	border-color: ${props => (props.selectedOption) ? '#0B4966' : '#FFF'};
	background: ${props => (props.selectedOption) ? '#0B4966' : '#FFF'};
`;

const ConteinerShare = styled.div`
	padding: 0 1.125rem;
	background: #FFF;
`;

const ModalTitle = styled.h1`
	padding: ${props => (props.title) && '.56rem'};
	font: 600 1rem "Open Sans", serif;
	font-weight: ${props => (props.selectedOption || props.title) ? '700' : '400'};
	color: ${props => (props.title) ? '#FFF' : '#0B4966'};
	background: ${props => (props.title) && '#0B4966'};
	border-radius: 5px 5px 0 0;
`;

const TextInvite = styled.p`
	padding: ${props => (props.link) ? props.link : '.8125rem .687rem'};
	font: 400 .75rem "Open Sans", serif;
	color: #8A8A8A;
	background: ${props => (props.share) && '#EDEDED'};
	border-radius: ${props => (props.share) && props.share};
	word-break: ${props => (props.share) && 'break-all'};
`;

const TextInviteSpan = styled.span`
	color: #0B4966;
	font-weight: 600;
	text-decoration: ${props => (props.link) && 'underline'};
	margin-left: ${props => (props.link) && '4px'};
`;

const FooterInvite = styled.p`
	padding: 1.25rem 2.0625rem;
	display: flex;
	background: #FFF;
	border-radius:  0 0 5px 5px;
	cursor: pointer;
`;

const FooterText = styled.p`
	margin: 0 .375rem;
	font: 600 1rem "Open Sans", serif;
	color: #0B4966;
`;

class ModalToInvite extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalToInvite: false,
			typeOption: ['Profissional de Saúde', 'Paciente'],
			selectedOption: 'Profissional de Saúde',
		}
	}

	renderPassToken = (userType) => {
		const { selectedOption } = this.state;
		const { roomAccess } = this.props;

		if (userType === 'paciente') {
			return roomAccess.patient_passcode
		}

		if ( userType === 'medico' && selectedOption === 'Paciente') {
			return roomAccess.patient_passcode
		}
		
		if (userType === 'medico' && selectedOption === 'Profissional de Saúde') {
			return roomAccess.doctor_passcode
		}

	}

	handleCopyPhrase = (userType) => {
		let { peers, roomAccess, displayName } = this.props;
		let listPeers = values(peers);
		let allPeers = listPeers.filter(item => this.renderTransformType(item.displayName) === 'Paciente').map(item => this.renderFormatName(item.displayName))

		const roomPatient = localStorage.getItem('passRoom');
		const urlElements = window.location.href.split('/')

		const Link = `${urlElements[0]}//${urlElements[2]}/atendimento/${roomAccess.roomId}`

		let phaseOne = `${this.renderFormatName(displayName)}, está te convidando para participar ${this.renderPhraseIsPlural(allPeers)}${allPeers.join(', ')}.`
		let phaseSecond = `Acesse o link ${Link}`
		let phaseThird = ` usando a senha ${this.renderPassToken(userType)}`;
		let phase = phaseOne + phaseSecond + phaseThird;

		navigator.clipboard.writeText(phase) && this.setState({ copiedMessage: true })
	}

	handleModalToInvite = (event) => {
		event.stopPropagation();
		this.setState({
			isModalToInvite: false,
		})
	}

	handleSelecOptionClick = (item) => {
		this.setState({
			selectedOption: item,
		});
	}

	renderTransformType = (name) => {
		let nameSplit = name.split(' ')
		let nameUser = nameSplit.pop()
		return (
			nameUser
		)
	}

	renderPhraseIsPlural = (item) => {
		if (item.length === 0) {
			return 'de uma consulta'
		} else if (item.length === 1) {
			return 'da consulta do paciente '
		} else {
			return 'da consulta dos pacientes '
		}
	}

	renderFormatName = (item) => {
		const username = item.split(' - ');
		const includesSpaceName = username[0].split(' ').join(' ');
		return (
			includesSpaceName
		)
	}

	renderPhase = (userType) => {
		const { peers, displayName, roomAccess } = this.props;

		const host = window.location.hostname;
		const urlElements = window.location.href.split('/')

		let listPeers = values(peers);
		let allPeers = listPeers.filter(item => this.renderTransformType(item.displayName) === 'Paciente').map(item => this.renderFormatName(item.displayName))

		const Link = `${urlElements[0]}//${urlElements[2]}/atendimento/${roomAccess.roomId}`

		return (
			<>
				<TextInvite id="title">Compartilhe essa mensagem para quem você quer que participe da consulta.</TextInvite>
				<TextInvite share={'5px 5px 0 0'} link={'0.9rem .687rem 0'}>
					{this.renderFormatName(displayName)} está te convidando para participar {this.renderPhraseIsPlural(allPeers)}{allPeers.join(', ')}.
				</TextInvite>
				<TextInvite share={'0 0 5px 5px'} link={'0 .687rem 0.8rem'}>Acesse o link
				<TextInviteSpan link>{Link}</TextInviteSpan> usando a senha <TextInviteSpan>{this.renderPassToken(userType)}</TextInviteSpan>
				</TextInvite>
			</>
		)
	}

	renderTypeOption = (userType, isModalChat) => {
		const { typeOption, selectedOption } = this.state;

		return typeOption.map(item => {
			return (
				<ButtomTypeInvite key={item} type={userType === 'paciente' || isModalChat } onClick={() => this.handleSelecOptionClick(item, userType)}>
					<ModalTitle selectedOption={selectedOption === item}>{item}</ModalTitle>
					<BorderInvite selectedOption={selectedOption === item} />
				</ButtomTypeInvite>
			)
		})
	}

	render() {
		const { displayName, handleModalToInvite, isModalChat, username, userType } = this.props;

		return (
			<>
				<Overlay onClick={handleModalToInvite}>
					<ContainerInvite onClick={e => e.stopPropagation()}>
						<HeaderInvite>
							<ModalTitle title>Convidar participante</ModalTitle>
							<ButtonIcon src={Close} alt='Close' onClick={handleModalToInvite} />
						</HeaderInvite>
						<SubheaderInvite>
							{this.renderTypeOption(userType)}
						</SubheaderInvite>
						<ConteinerShare>
							{this.renderPhase(userType)}
						</ConteinerShare>
						<FooterInvite onClick={() => this.handleCopyPhrase(userType)}>
							<img src={Copy} alt="Copiar" />
							<FooterText>{!this.state.copiedMessage ? 'Copiar Mensagem' : 'Mensagem Copiada'}</FooterText>
						</FooterInvite>
					</ContainerInvite>
				</Overlay>
			</>
		);
	}
}

export default connect(mapStateToProps, null)(ModalToInvite);
