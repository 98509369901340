import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

// import Header from "../../components/header";

const GridContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 75px);
  display: flex;
  justify-content: ${(props) => (props.showChatOnly || props.loading ? "center" : "initial")};
  align-items: ${(props) => (props.showChatOnly || props.loading ? "center" : "initial")};
  flex-direction: ${(props) => (props.loading && 'column')};

  @media (max-width: 667px) {
    width: 100%;
  }
`;

const animation = keyframes`
  0%{
    background-position: -400px 0;
  }
  100%{
    background-position: 400px 0;
  }
`;


const LoadingContainer = styled.span`
  margin-bottom: 1.313rem;
  width: 400px;
  height: 3.5px;
  border-radius: 4px;
	animation-duration:  3s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: ${animation};
	animation-timing-function: linear;
	background: linear-gradient(to right, #0561af 0%, #fff 100%, #0561af 0% );
	background-size: 1000px 104px;
	overflow: hidden;

  @media(max-width: 648px) {
    width: 300px;
  }
`;

const LoadingText = styled.div`
  color: #FFF;
  font: 700 1.25rem 'Opens Sans', sans-serif;
`;

class Loading extends Component {
  render() {
    const showVideoOnly = true;
    const showChatOnly = false;
    return (
      <GridContainer loading showVideoOnly={showVideoOnly} showChatOnly={showChatOnly}>
        <LoadingContainer />
        <LoadingText>Preparando consulta</LoadingText>
      </GridContainer>
    );
  }
}

export default Loading;
