import React, { Fragment, Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { persistStore } from 'redux-persist';
import WebFont from "webfontloader";

// Css
import "./index.css";

// store
import store from "./dataflow/store";

// pages
import Private from "./components/private";
import NotFound from "./components/notfound";

import PassToken from './pages/PassToken/index';
import DoctorRoom from "./pages/DoctorRoom/Room";
// import PacientRoom from "./pages/PacientRoom/Room";

WebFont.load({
  google: {
    families: ["Open Sans"]
  }
});

persistStore(store, {
  whitelist: ['auth']
}, () => {
  render((
    <Provider store={store}>
      <Fragment>
        <BrowserRouter>
          <Suspense fallback={<div />}>
            <Switch>
              <Route
                exact
                path="/atendimento/:roomId"
                render={props => <PassToken {...props} />}
              />
              <Route exact path="/atendimento/paciente/:roomId" render={props =>
                <Private {...props} type='paciente'>
                  <DoctorRoom />
                </ Private>}
              />
              <Route exact path="/atendimento/medico/:roomId" render={props =>
                <Private {...props} type='medico'>
                  <DoctorRoom />
                </ Private>}
              />
              <Route
                render={props => <NotFound {...props} />}
              />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </Fragment>
    </Provider>
  ), document.getElementById('root'));
}
);