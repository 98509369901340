// Libs
import { compose, createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";

import * as SWRTC from "@andyet/simplewebrtc";

// Reducers
import Authentication from "./modules/authentication-module";
import Chat from "./modules/chat-module";


const reducers = combineReducers({
  auth: Authentication,
  chat: Chat,
  simplewebrtc: SWRTC.reducer
});

const rootReducer = (state, action) => {
  if (action.meta && action.meta.logout) {
    state = undefined; // eslint-disable-line no-param-reassign
  }

  return reducers(state, action);
};

const configureStore = initialState => {
  const bundle = compose(
    applyMiddleware(thunkMiddleware),
  );
  const createStoreWithMiddleware = bundle(createStore);
  const store = createStoreWithMiddleware(
    rootReducer,
    initialState,
    window.devToolsExtension ? window.devToolsExtension() : f => f
  );

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept(reducers, () => {
        store.replaceReducer(reducers);
      });
    }
  }

  return store;
};

export default configureStore({});
