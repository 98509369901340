// Libs
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { values } from "lodash";
import {
  ChatComposers,
  ChatList,
  Actions,
} from "@andyet/simplewebrtc";

// Components
import Messages from "./Messages";
import ModalToInvite from '../../components/modalToInvite';

//Redux
import { isWriting, isNotification } from '../../dataflow/modules/chat-module.js';

// Images
import close from '../../assets/close-white.svg';
import send from '../../assets/send.svg';
import chat from '../../assets/color-chat.svg';
import avatar from '../../assets/peoples.svg';
import camIcon from '../../assets/video-white.svg';
import addPeople from '../../assets/addPeople.svg';

const mapStateToProps = state => ({
  peers: state.simplewebrtc.peers,
});

const mapDispatchToProps = dispatch => ({
  onChat: (room, options) => dispatch(Actions.sendChat(room, options)),
  onRTT: (room, rtt) => dispatch(Actions.sendRTT(room, rtt)),
  isWriting: info => {
    dispatch(isWriting(info));
  },
  isNotification: info => {
    dispatch(isNotification(info));
  },
})

const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const configUrl = `https://api.simplewebrtc.com/config/guest/2e3ea92f92b90f5ec787d37f`;

// Styles
const Content = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
  width: ${props => (props.chatOnly) ? '70%' : '26%'};
  max-width: 400px;
  height: ${props => props.height};
  display: ${props => (props.isChatOpenedo) ? 'flex' : 'none'};
  flex-direction: ${props => (props.chatOnly) ? 'column' : 'column'};
  background: #fff;
  box-shadow: 0 0 10px #00000019;
  overflow: hidden;
  z-index: 2;
  /* display: ${props => (props.chatOnly) ? 'flex' : 'block'}; */

  @media(max-width: 1024px) {
    width: 40%;
  }

  @media(max-width: 900px) {
    width: 60%;
  }

  @media (max-width: 667px) {
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${props => (props.subheader) ? '80%' : '100%'};
  min-height: 2.625rem;
  height: 2.625rem;
  padding: 0 .4rem 0 1rem;
  ${'' /* padding: 0 2.5%; */}
  background: ${props => (props.subheader) ? '#fff' : '#0B4966'};
  z-index: 10;
  background: ${props => (props.subheader) ? '#fff' : '#0B4966'};
  margin: 0 auto;

  @media(max-width: 648px) {
    width: ${props => (props.subheader) && '100%'};
  }
`;

const Icon = styled.img`
  margin-right: .313rem;
  width: ${props => (props.icon === 'chat') ? '18.5px' : '24px'};
`;

const Title = styled.h3`
  margin: 0;  
  color: ${props => (props.subheader) ? '#0B4966' : '#FFF'};
  font-size: ${props => (props.subheader) ? '0.875rem' : '1rem'};
  font-weight: ${props => (props.selectedOption) ? '600' : '400'};
  font-family: 'Open Sans', sans-serif;
  text-transform: ${props => (props.chatTitle) ? null : 'capitalize'};
`;

const ChatHistory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100% - 9.75rem);
  background: #FAFAF8;

  &::-webkit-scrollbar {
    width: 1px;
    background: transparent;
  }

  @media (max-width: 667px) {
    width: 100%;
    height: calc(100% - 90px);
    background: '#FAFAF8';
  }

  > div {
    width: 100%;
    height: auto;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 1px;
      background: transparent;
    }

    @media (max-width: 667px) {
      width: 100%;
      height: auto;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 1px;
        background: transparent;
      }

      ${({ hasVideo }) => hasVideo && `
        position: absolute;
        bottom: 100px;
        left: 0;
        height: auto;
        max-height: 100%;
      `}
    }
  }
`;

const Footer = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.25rem;
  padding: 0 1rem;
  background: #fff;

  @media (max-width: 667px) {
    height: 74px;
    padding: 0;

    & > textarea {
      padding: .25rem;
    }
  }
`;

const VideoCallbutton = styled.button`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #FFF;
  border: none;
  outline: none;
  cursor: pointer;
`;

const JustVideoCallButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0 16px;
  background: none;
  outline: none;
  font: 200 1rem 'Open Sans', sans-serif;
  color: #fff;
  cursor: pointer;
  z-index: 4;
`;

const ButtonIcon = styled.img`
  width: 31px;
  margin-right: .5rem;
  cursor: pointer;
`;

const Input = styled.input`
  width: 90%;
  height: 100%;
  padding: 0.75rem;
  border: none;
  font: 400 .9rem 'Open Sans', serif;
  color: #4D4D4D;
  resize: none;
  outline: none;
`;

const ChatButton = styled.button`
  padding-right: .5rem;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
`

const ChatSend = styled.span`
  display: block;
  width: 32px;
  height: 32px;
  
  &::before {
    content: url(${send});
  }
`

const Button = styled.button`
	position: relative;
	display: ${(props) => (props.display ? "none" : "flex")};
	height: 42px;	
  background: #FFF;
  align-items: center;
	border: none;
  outline: none;
	cursor: pointer;
`;

const Border = styled.span`
	position: absolute;
	border: 1.5px solid #0B4966;
	border-radius: 3px;
	bottom: 0;
	width: 100%;
	border-color: ${props => (props.selectedOption) ? '#0B4966' : '#FFF'};
	background: ${props => (props.selectedOption) ? '#0B4966' : '#FFF'};
`;

const ContainetParticipants = styled.div`
  flex: 1;
  width: 100%;
  padding: 1.375rem 1.4rem 0 1.4rem;
  background: #F1F1F1;
  overflow-y: scroll;
`;

const ContentDoctor = styled.div`
  padding-bottom: .75rem;
  margin-bottom: 1.8125rem;
  border-bottom: 1px solid #e6e6e6;
`;

const ContentPeers = styled.div`
  padding-bottom: 1.0625rem;
  display: flex;
  flex-direction: row;
	${(props) => (props.button ? "cursor: pointer" : null)};
	margin-left: ${props => (props.button) && '10px'};
`;

const Avatar = styled.p`
  margin-right: 0.75rem;
  background: #0B4966;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-radius: 50%;
  color: #FFF;
  font: 400 1.125rem 'Open Sans', serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

const ContentName = styled.div`
  width: 74%;
`;

const Text = styled.p`
  color: #000000;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
`;

const TextType = styled.p`
	margin-left: 10px;
  padding-bottom: 0.9375rem;
  color: #8A8A8A;
  font: 400 0.875rem 'Open Sans', sans-serif;
`;

const TextToInvite = styled.p`
  padding-left: 0.6875rem;
  color: #0B4966;
  font: 600 0.875rem 'Open Sans', sans-serif;
`;

const TextName = styled.p`
  color: #000000;
  font: 400 0.875rem 'Open Sans', sans-serif;
  font-weight: ${props => (props.type) && '300'};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

class ChatContainer extends Component {
  constructor() {
    super();
    this.state = {
      text: '',
      selectedOption: 'chat',
      isModalToInvite: false,
      typeSelected: '',
      buttons: [
				{
					icon: chat,
					text: 'chat',
				},
				{
					icon: avatar,
					text: 'participantes',
				}
			],
    }
  }

  handleModalToInvite = (item) => {
    this.setState({
      isModalToInvite: !this.state.isModalToInvite,
      typeSelected: item,
    });
	}

  handleSelectOptionClick = (item) => {
		this.setState({
			selectedOption: item,
		});
	}

  handleChange = event => {
    if (event.target.value.length === 1 && event.target.value.trim() === '') {
      return
    }
    this.setState({ text: event.target.value });
    this.props.onRTT(this.props.roomAddress, event.target.value);
  }

  handleSend = (event) => {
    event.preventDefault();
    const { roomAddress, displayName } = this.props

    if (this.state.text) {
      this.props.onChat(roomAddress, {
        body: this.state.text,
        displayName: displayName
      })

      this.setState({
        text: ''
      });
    }
  }

  chooseContentHeight = () => {
    const { hasVideo, chatOnly } = this.props;

    if (hasVideo) {
      return '100%';
    }

    if (chatOnly) {
      return '95vh';
    }

    return `${window.innerHeight - 90}px`;
  }

  handleIsWriting = (type) => {
    this.props.isWriting(type);
  }

  renderButtons = () => {
		const { selectedOption } = this.state;

		return this.state.buttons.map(item => {
			return (
				<Button onClick={() => this.handleSelectOptionClick(item.text)} display={this.props.login && item.text === 'Convidar'} displayMob={item.text === 'Ajuda' || this.props.login}>
					<Icon src={item.icon} icon={item.text} alt="Icone de ajuda" />
					<Title subheader selectedOption={selectedOption === item.text}>{item.text}</Title>
					<Border selectedOption={selectedOption === item.text} />
				</Button>
			)
		})
  }
  
  renderChat = () => {
    const {
      roomAddress,
      displayName,
      hasVideo,
      chatOnly,
    } = this.props;
   
    return (
      <>
        <ChatHistory
          ref={(node) => {
            this.chatHistory = node;
          }}
          hasVideo={hasVideo}
          chatOnly={chatOnly}
          className="chat-history"
        >
          <ChatList
            room={roomAddress}
            renderGroup={({ chats }) => {
              return (
                <Messages
                  key={chats[0].id}
                  chats={chats || []}
                  displayName={displayName}
                  scrollbar={this.chatHistory}
                  hasVideo={hasVideo}
                />
              );
            }}
          />
        </ChatHistory>
        <Footer
          onSubmit={this.handleSend}
        >
          <Input
            autoFocus
            value={this.state.text}
            onFocus={() => this.handleIsWriting(true)}
            onBlur={() => this.handleIsWriting(false)}
            placeholder="Escreva sua mensagem"
            onChange={this.handleChange}
          />
          <ChatButton
            type="submit"
          >
            <ChatSend />
          </ChatButton>
          <div>
            <ChatComposers room={roomAddress} />
          </div>
        </Footer>
      </>
    )
  }

  renderTransformType = (name) => {
		let nameSplit = name.split(' ')
		let nameUser = nameSplit.pop()
		return (
			nameUser
		)
  }

  renderFormatName = (item) => {
		const username = item.split(' - ');
		const includesSpaceName = username[0].split(' ').join(' ');
		return (
			includesSpaceName
		)
  }
  
  handleFirstLetter = (item) => {
    const name = item.split(' - ');
    const displayName = name[0].split(' ');
    const firstLetter = displayName.map((x, i) => i < 2 && x[0])
    return firstLetter
  }
  
  renderParticipants = () => {
    const {
      displayName,
      peers,
    } = this.props;

    const name = displayName.split(' - ');
		const username = name[0].split(' ');
    let listPeers = values(peers);
		let allPeersDoctor = listPeers.filter(item => this.renderTransformType(item.displayName) === 'Médico').map(item => this.renderFormatName(item.displayName));
    let allPeersPatient = listPeers.filter(item => this.renderTransformType(item.displayName) === 'Paciente').map(item => this.renderFormatName(item.displayName));
    
    return (
      <ContainetParticipants>
        <ContentDoctor>
          <TextType>Equipe Médica</TextType>
          <ContentPeers button  onClick={() => this.handleModalToInvite('doctor')}>
            <img src={addPeople} alt='Add People' />
            <TextToInvite>Convidar Médico</TextToInvite>
          </ContentPeers>
          <div>
            {allPeersDoctor && allPeersDoctor.map(item => (
              <ContentPeers>
                <Avatar>{this.handleFirstLetter(item)}</Avatar>
                <ContentName >
                  <TextName>{item}</TextName>
                  <TextName type>Médico</TextName>
                </ContentName>
              </ContentPeers>
            ))}
          </div>
        </ContentDoctor>
        <div>
          <TextType>Pacientes</TextType>
          <ContentPeers button onClick={() => this.handleModalToInvite('patient')}>
            <img src={addPeople} alt='Add People' />
            <TextToInvite>Convidar Paciente</TextToInvite>
          </ContentPeers>
          <div>
            {allPeersPatient && allPeersPatient.map(item => (
              <ContentPeers>
                <Avatar>{this.handleFirstLetter(item)}</Avatar>
                <ContentName>
                  <TextName>{item}</TextName>
                  <TextName type>Paciente</TextName>
                </ContentName>
              </ContentPeers>
            ))}
          </div>
        </div>
      </ContainetParticipants>
    )
  }

  render() {
    const {
      displayName,
      toggleVideo,
      hasVideo,
      chatOnly,
      isVideo,
      toggleChat,
      isChatOpened,
    } = this.props;
    const { selectedOption, isModalToInvite, typeSelected } = this.state;

    return (
      <>
        <Content
          height={this.chooseContentHeight()}
          hasVideo={hasVideo}
          chatOnly={chatOnly}
          isChatOpenedo={isChatOpened}
          className="chat-container"
        >
          {chatOnly && (
            <>
              <Header>
                <Title chatTitle>Chat da Consulta</Title>
                <JustVideoCallButton onClick={toggleChat}>
                  <ButtonIcon src={camIcon} />
                  Iniciar vídeo chamada
                </JustVideoCallButton>
              </Header>
            </>
          )}
          {!chatOnly && (
            <>
              <Header>
                <Title chatTitle selectedOption>Detalhes da Consulta</Title>
                <ButtonIcon src={close} onClick={toggleChat} />
              </Header>
              <Header subheader>
                {this.renderButtons()}
              </Header>
            </>
          )}
          {selectedOption === 'chat'
            ? this.renderChat()
            : !chatOnly && this.renderParticipants()
          }
        </Content>
        {isModalToInvite &&
          <ModalToInvite
            // peers={peers}
            displayName={displayName}
            roomAccess
            handleModalToInvite={this.handleModalToInvite}
            typeSelected
          />
        }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer);