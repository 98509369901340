import React, { Component } from "react";
import styled from "styled-components";
import Chat from "./chat";

const GridContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 75px);
  display: flex;
  justify-content: initial;
  align-items: center;
  flex-direction: 'column';

  @media (max-width: 667px) {
    width: 100%;
  }
`;

const InnerGrid = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  width: 90%;
  height: calc(100% - 25px);
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  width: ${(props) => (props.isChatOpened ? "75%" : "90%")};

  @media (max-width: 667px) {
    width: 100%;
    height: 100%;
  }
`;

class VideoContainer extends Component {
  render() {
    const { displayName, room, sendRtt, toggleChat } = this.props;

    return (
      <GridContainer chatOnly>
        <InnerGrid>
          <Chat
            {...this.props}
            sendRtt={sendRtt}
            roomAddress={room.address}
            displayName={displayName}
            isChatOpened
            chatOnly
            toggleChat={toggleChat}
          />
        </InnerGrid>
      </GridContainer>
    );
  }
}

export default VideoContainer;
