// Action Types
export const IS_WRITING = "sirio/chat/IS_WRITING";
export const IS_NOTIFICATION = "sirio/chat/IS_NOTIFICATION";
export const IS_OPEN_MSG = "sirio/chat/IS_OPEN_MSG";
export const IS_READ = "sirio/chat/IS_READ";


export const initialState = {
  isWriting: undefined,
  isNotification: false,
  isOpenMSG: false,
  isRead: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case IS_WRITING:
      return Object.assign({}, state, {
        isWriting: action.info,
      });
    case IS_NOTIFICATION:
      return Object.assign({}, state, {
        isNotification: action.info,
      });
    case IS_OPEN_MSG:
      return Object.assign({}, state, {
        isOpenMSG: !state.isOpenMSG,
      })
      case IS_READ:
        return Object.assign({}, state, {
          isRead: action.info,
        })
    default:
      return state;
  }
};

// Action Creators
export const isWriting = info => ({
  type: IS_WRITING,
  info,
});

export const isNotification = info => ({
  type: IS_NOTIFICATION,
  info,
});

export const isOpenMSG = info => ({
  type: IS_OPEN_MSG,
  info,
});

export const isRead = info => ({
  type: IS_READ,
  info,
});

